import { LinkSimpleIcon, LinkSimpleSlashIcon } from '@panwds/icons';
import _ from 'lodash';

export const getCSPIdFromSN = (sn: string, integrationsAll: any = []) => {
    let cspId: any = undefined;
    integrationsAll.forEach((entry: any) => {
        const match = _.find(entry.Integrations, {
            Panorama: { SerialNumber: sn },
        });
        if (match && !cspId) {
            cspId = entry.CspId;
        }
    });
    return cspId;
};

// will generate a mapping object between:
// Serial Number select displayed format and integration object, sample:
// 'serialnnumber123 [device] - CSPID: 123'
// mapped to:
// {"Panorama": {"SerialNumber": "serialnnumber123", "DeviceName": "device"},"CDL": {"Enabled": true}},
export const generateIntegrationsMap = (integrationsAll: any) => {
    const mapping: any = {};
    integrationsAll.forEach((entry: any) => {
        entry.Integrations.forEach((integration: any) => {
            const sn = integration.Panorama.SerialNumber;
            const device = integration?.Panorama?.DeviceName;
            mapping[
                sn + (device ? ` [${device}]` : "") + ` - CSPID: ${entry.CspId}`
            ] = integration;
        });
    });
    return mapping;
};

// will generate a mapping object between:
// serian number to its integrationsAll cspId
export const generateSNtoCSPIDMap = (integrationsAll: any) => {
    const serianNumberCSPIDMap: any = {};
    integrationsAll.forEach((entry: any) => {
        entry.Integrations.forEach((integration: any) => {
            serianNumberCSPIDMap[integration.Panorama.SerialNumber] =
                entry.CspId;
        });
    });
    return serianNumberCSPIDMap;
};


export const getValuesForSecondarySN = (PrimarySN: string, integrationMap: any, SNcspIdMap: any) => {
    if (!PrimarySN) {
        return [];
    }

    const isEnabled = !!integrationMap[PrimarySN]?.CDL?.Enabled;
    const serialNumber = integrationMap[PrimarySN]?.Panorama?.SerialNumber;

    return Object.keys(integrationMap)
        .filter(k => (
            SNcspIdMap[serialNumber] === SNcspIdMap[integrationMap[k].Panorama.SerialNumber] &&
            !!integrationMap[k].CDL.Enabled === isEnabled &&
            serialNumber !== integrationMap[k].Panorama.SerialNumber
        ))
        .map(k => {
            if (integrationMap[k].CDL?.Enabled) {
                return { value: k, iconLeft: <LinkSimpleIcon size="sm" /> };
            }
            return { value: k, iconLeft: <LinkSimpleSlashIcon size="sm" /> };
        });
}

export const getStatusIcon = (status: "active" | "pending") => {
    if (status === "active") {
        return {backgroundColor: '#33CCB8'};
    }
    if (status === "pending") {
        return {backgroundColor: '#EF9700'};
    }
    return {backgroundColor: '#707070'};
};

export const getStatusText = (status: "active" | "pending", translate: any) => {
    if (status === "active") {
        return translate(`resources.integrations.values.active`);
    }
    if (status === "pending") {
        return translate(`resources.integrations.values.pending`);
    }
    return translate(`resources.integrations.values.inactive`);
};
//generate cloudManager integrations map
export const generateCloudManagerIntegrationsMap = (integrationsAll: any) => {
    const mapping: any = [];
    integrationsAll.forEach((entry: any) => {
       mapping.push(entry.Integrations);
    });
    return mapping;
};

export const getTsgId = (tenantId: string, integrationsMap: any = []) => {
    let tsgId: any = undefined;
    const match = integrationsMap.find((o: any) => o.TenantId === tenantId )
    tsgId = match?.TsgId;
    return tsgId;
}

export const getTenantName = (tenantId: string, integrationsMap: any = []) => {
    let tenantName: any = undefined;
    const match = integrationsMap.find((o: any) => o.TenantId === tenantId )
    tenantName = match?.TenantName;
    return tenantName;
}

export const getCDLRegion = (tenantId: string, integrationsMap: any = []) => {
    let CDLRegion: any = undefined;
    const match = integrationsMap.find((o: any) => o.TenantId === tenantId )
    CDLRegion = match?.CDLRegion;
    return CDLRegion;
}

export const getCDLId = (tenantId: string, integrationsMap: any = []) => {
    let CDLId: any = undefined;
    const match = integrationsMap.find((o: any) => o.TenantId === tenantId )
    CDLId = match?.CDLId;
    return CDLId;
}

export const getUrl = (tenantId: string, integrationsMap: any = []) => {
    let url: any = undefined;
    const match = integrationsMap.find((o: any) => o.TenantId === tenantId )
    url = match?.Url;
    return url;
}

export const getEntitlementRequired = (tenantId: string, integrationsMap: any = []) => {
    let url: any = undefined;
    const match = integrationsMap.find((o: any) => o.TenantId === tenantId )
    url = match?.EntitlementRequired;
    return url;
}

export const getRegion = (tenantId: string, integrationsMap: any = []) => {
    let region: any = undefined;
    const match = integrationsMap.find((o: any) => o.TenantId === tenantId )
    region = match?.Region;
    return region;
}

export const getCSPIdFromTenantName = (tenantId: string, integrationsAll: any = []) => {
    let cspId: any = undefined;
    integrationsAll.forEach((entry: any) => {
        const match = _.find(entry.Integrations, {
            TenantId: tenantId,
        });
        if (match && !cspId) {
            cspId = entry.CspId;
        }
    });
    return cspId;
};
