import {useTranslate} from "../../../../customHooks";
import {FormControl, Grid} from "@material-ui/core";
import {Field, FormSpy, useForm, useFormState} from "react-final-form";
import {PANWDSCheckboxField, PANWDSInput, PANWDSRadioField} from "../../../../components/PANWDSElements";
import {Row} from "../../../../components/FormElements";
import * as React from "react";
import {isRequired, isValidIPAMPoolId} from "../../../../utils/validate";
import {OnChange} from "react-final-form-listeners";
import {useState} from "react";

const Index = () => {
    const translate = useTranslate();
    const form = useForm();
    const formState = useFormState();

    const [isOwnPublicIPs, setIsOwnPublicIPs] = useState<boolean>(false);

    const handleIPPoolTypeChange = (selected: string) => {
        setIsOwnPublicIPs(selected === 'BYOIP');
        if (selected === 'AWSService') {
            form.reset({...formState.values, IPAMPoolId: undefined});
        }
    };

    return (
        <>
            <Row>
                <Grid item sm={12} className="noPadding" style={{paddingBottom: "1rem"}}>
                    <FormControl>
                        <Field
                            name="EgressNatEnable"
                            // @ts-ignore
                            component={PANWDSCheckboxField}
                            defaultValue={false}
                            label={translate(`resources.firewalls.fields.EgressNAT.EnableEgressNat`)}
                            type="checkbox"
                            dataMetrics={"cloudngfw-firewall-create-egressNatEnabled"}
                            muted={translate(`resources.firewalls.fields.EgressNAT.EnableEgressNatMutedText`)}
                        />
                    </FormControl>
                </Grid>
            </Row>
            <FormSpy subscription={{values: true}}>
                {props => {
                    let EgressNatEnable = props?.values?.EgressNatEnable;
                    if (EgressNatEnable) {
                        return (
                            <>
                                <Row>
                                    <Grid item xs={12} sm={6} className="noPadding">
                                        <FormControl fullWidth>
                                            <Field
                                                name="IPPoolType"
                                                row
                                                // @ts-ignore
                                                component={PANWDSRadioField}
                                                initValue={EgressNatEnable ? 'AWSService' : 'BYOIP'}
                                                options={[
                                                    {label: translate(`resources.firewalls.fields.EgressNAT.AWSService`), value: "AWSService"},
                                                    {label: translate(`resources.firewalls.fields.EgressNAT.BYOIP`), value: "BYOIP"}
                                                ]}
                                                title={"Public IPs"}
                                                required
                                                validate={isRequired}
                                                defaultValue={EgressNatEnable ? 'AWSService' : 'BYOIP'}
                                                dataMetrics={"cloudngfw-firewall-create-egress-nat-public-ips"}
                                            />
                                            <OnChange name="IPPoolType">
                                                {(selected: any) => handleIPPoolTypeChange(selected)}
                                            </OnChange>
                                        </FormControl>
                                    </Grid>
                                </Row>
                                {isOwnPublicIPs && <Row>
                                    <Grid item xs={12} sm={6} className="noPadding">
                                        <FormControl fullWidth>
                                            <Field
                                                name="IPAMPoolId"
                                                placeholder={"Please enter a IPAM pool id (e.g., ipam-pool-0123456789abcdef)"}
                                                // @ts-ignore
                                                component={PANWDSInput}
                                                validate={isValidIPAMPoolId}
                                                required
                                                dataMetrics="cloudngfw-firewall-create-egress-nat-public-own-ips"
                                            />
                                        </FormControl>
                                    </Grid>
                                </Row>}
                            </>
                        )
                    } else {
                        return <></>;
                    }
                }}
            </FormSpy>
        </>
    );
};

export default Index;
